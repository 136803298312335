import React, { useState, useEffect } from 'react';

function ConsentBanner({ setConsentGiven }) {
    const [consent, setConsent] = useState(null);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const savedConsent = localStorage.getItem('cookieConsent');
        if (savedConsent) {
            setConsent(savedConsent);
            setConsentGiven(savedConsent === 'true');
            setIsVisible(false);
        }
    }, [setConsentGiven]);

const handleAccept = () => {
    setConsent(true);
    setConsentGiven(true);
    localStorage.setItem('cookieConsent', 'true');  // Spara som en sträng i localStorage

    // Kontrollera om Google Analytics redan är laddat innan du laddar det igen
    if (!window.gtag) {
        loadAnalytics();
    }

    setIsVisible(false);
};


    const handleDecline = () => {
        setConsent(false);
        setConsentGiven(false);
        localStorage.setItem('cookieConsent', false);
        setIsVisible(false);
    };

    const loadAnalytics = () => {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-V0RKP9BKCZ';
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-V0RKP9BKCZ');
        };
    };

    useEffect(() => {
        if (consent === 'true') {
            loadAnalytics();
        }
    }, [consent]);

    if (!isVisible) return null;

    return (
        <div style={bannerStyles}>
            <p style={textStyles}>
                Vi använder cookies för att förbättra användarupplevelsen och analysera trafik. Genom att acceptera, godkänner du användningen av cookies.
            </p>
            <div style={buttonContainerStyles}>
                <button onClick={handleAccept} style={acceptButtonStyles}>Acceptera</button>
                <button onClick={handleDecline} style={declineButtonStyles}>Neka</button>
            </div>
        </div>
    );
}

const bannerStyles = {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#CCC',
    color: 'white',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column', // Gör så att texten och knapparna placeras i kolumn
    alignItems: 'center',
    zIndex: '1000',
    textAlign: 'center', // Centrerar texten horisontellt
};

const textStyles = {
    maxWidth: '800px',
    padding: '0 20px',

};

const buttonContainerStyles = {
    display: 'flex',
    gap: '10px',
};

const acceptButtonStyles = {
    backgroundColor: '#EEE', // Grön färg för Acceptera
    color: '#4e4e4e',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
};

const declineButtonStyles = {
    backgroundColor: '#ccc', // Grå färg för Neka
    color: '#555', // Mörkare grå text
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
};

export default ConsentBanner;
