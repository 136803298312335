import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box
            sx={{
                padding: '0px', // Mindre padding
                maxWidth: '100%',
                margin: '0 auto',
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: '10px', marginBottom: '40px' }}>
                Frequently Asked Questions (FAQ)
            </Typography>

            {/* Kategori: Resebeställningar */}
            <Typography variant="h6" gutterBottom sx={{ marginBottom: '10px', fontWeight: 'bold'}}>
                Resebeställningar
            </Typography>

            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel1-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Hur fungerar en överraskningsresa?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Du fyller i dina önskemål, preferenser och budget i vårt bokningsformulär. Vi gör vårt bästa för att planera en skräddarsydd överraskningsresa baserat på de aktiviteter och destinationer du har valt. Under resans gång får du instruktioner och ledtrådar via SMS.
                    </Typography>
                </AccordionDetails>
            </Accordion>


<Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
    <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        id="panel15-header"
        sx={{ paddingY: '0px' }}
    >
        <Typography variant="h6">Tar ni ut en avgift för tjänsten?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ paddingTop: '0px' }}>
        <Typography variant="body1">
            Ja, vi tar ut en avgift på 10-30% beroende på flera faktorer, såsom din totala budget, hur många aktiviteter som inkluderas, och hur mycket research som krävs för att planera resan. Vår avgift täcker arbetet med att skapa en skräddarsydd och oförglömlig resa, samt hanteringen av alla detaljer som ingår.
        </Typography>
    </AccordionDetails>
</Accordion>


<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
    <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        id="panel2-header"
        sx={{ paddingY: '0px' }}
    >
        <Typography variant="h6">Behöver jag ta med egna pengar under resan?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ paddingTop: '0px' }}>
        <Typography variant="body1">
            Ja, du kommer att behöva egna pengar under resan. Vi bokar vissa aktiviteter och upplevelser i förväg som ingår i din överraskningsresa, men andra saker som restaurangbesök eller vissa aktiviteter kan kräva att du själv betalar på plats.
        </Typography>
    </AccordionDetails>
</Accordion>



<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
    <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        id="panel3-header"
        sx={{ paddingY: '0px' }}
    >
        <Typography variant="h6">Kan jag avboka resan?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ paddingTop: '0px' }}>
        <Typography variant="body1">
            När du har fått bekräftelsen från oss och betalat för resan, kan vi tyvärr inte avboka den. Vi rekommenderar att du ser över alla detaljer innan betalning för att säkerställa att allt stämmer med dina förväntningar.
        </Typography>
    </AccordionDetails>
</Accordion>


            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel4-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Kan jag göra ändringar när resan har påbörjats?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Nej, när resan har påbörjats kan vi tyvärr inte göra några ändringar. Alla ändringar måste göras innan resan bekräftas. När du väl är på väg är det en del av äventyret att följa med på resans gång.
                    </Typography>
                </AccordionDetails>
            </Accordion>

<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
    <AccordionSummary 
        expandIcon={<ExpandMoreIcon />} 
        id="panel5-header"
        sx={{ paddingY: '0px' }}
    >
        <Typography variant="h6">Kan vi neka att genomföra din resa?</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ paddingTop: '0px' }}>
        <Typography variant="body1">
            Ja, vi kan neka att genomföra din resa om vi anser att den är för nära inpå det föreslagna avresedatumet eller för svår att genomföra enligt dina önskemål.
        </Typography>
    </AccordionDetails>
</Accordion>

                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel6-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Vad händer om jag är missnöjd med planeringen?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Vi planerar resan baserat på de preferenser du anger, och vi gör vårt bästa för att möta dina förväntningar. Om det är något du absolut inte vill inkludera, som specifika aktiviteter eller transportmedel (tåg, buss etc.), kan du meddela detta i förväg. Observera att viss fritid ingår, eftersom aktiviteterna inte pågår hela tiden, och du får vara beredd på viss öppenhet och spontanitet.
                    </Typography>
                </AccordionDetails>
            </Accordion>


            {/* Kategori: Aktiviteter */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
                Aktiviteter
            </Typography>

            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel7-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Vilka typer av aktiviteter ingår?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Aktiviteter kan variera beroende på dina val. Det kan inkludera allt från sportaktiviteter och kulturella upplevelser till avkoppling och naturäventyr.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel8-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Kan jag välja vilka aktiviteter jag vill ha?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Ja, du kan specificera vilka aktiviteter du föredrar när du bokar resan. Vi anpassar resan efter dina önskemål så gott vi kan.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Kategori: Betalningar */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
                Betalningar
            </Typography>

            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel9-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Hur betalar jag för resan?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        När din resa har bekräftats skickar vi en betalningslänk via e-post eller SMS. Du kan betala med de flesta vanliga betalningsmetoder.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel10-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Kan jag få återbetalning om jag inte är nöjd?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Eftersom varje resa är unikt planerad baserat på dina önskemål, är återbetalningar inte möjliga. Vi gör dock vårt bästa för att hantera justeringar där det är möjligt.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Kategori: Säkerhet och Hälsa */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
                Säkerhet och Hälsa
            </Typography>

            <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel11-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Vad händer om jag blir sjuk under resan?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Om du blir sjuk under resan kan vi hjälpa till att arrangera vård. Vi rekommenderar alltid att du har en giltig reseförsäkring som täcker medicinska kostnader.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel12-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Vad händer om jag råkar ut för en nödsituation?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Vid en nödsituation under resan finns vi tillgängliga för att ge stöd och assistans. Vi rekommenderar att du alltid har kontaktuppgifter till lokala myndigheter och nödhjälp.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            {/* Kategori: Tillgänglighet */}
            <Typography variant="h6" gutterBottom sx={{ marginTop: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
                Tillgänglighet
            </Typography>

            <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel13-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Kan ni anpassa resor för personer med funktionsnedsättningar?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Ja, vi kan anpassa resor för personer med särskilda behov. Var vänlig kontakta oss i förväg så att vi kan planera därefter.
                    </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
                <AccordionSummary 
                    expandIcon={<ExpandMoreIcon />} 
                    id="panel14-header"
                    sx={{ paddingY: '0px' }}
                >
                    <Typography variant="h6">Kan jag ange särskilda tidsönskemål för aktiviteter?</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Typography variant="body1">
                        Ja, du kan specificera önskad tid för transport eller aktiviteter, men vi kan inte garantera att alla önskemål kan uppfyllas.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default FAQ;
