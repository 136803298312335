import React, { useState, useEffect } from 'react';
import ConsentBanner from './ConsentBanner';
import BookingForm from './BookingForm';

function App() {
    const [consentGiven, setConsentGiven] = useState(null);

    useEffect(() => {
        const savedConsent = localStorage.getItem('cookieConsent');
        if (savedConsent) {
            setConsentGiven(savedConsent === 'true');
        }
    }, []);

    return (
        <div>
            {/* Cookie-banner visas alltid */}
            <ConsentBanner setConsentGiven={setConsentGiven} />
            
            {/* BookingForm laddas alltid, men ingen spårningsdata samlas in innan samtycke */}
            <BookingForm />
        </div>
    );
}

export default App;
