import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import {
    TextField,
    Button,
    Typography,
    Container,
    Grid,
    CircularProgress,
    Box,
    Card,
    CardActionArea,
    CardMedia,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    RadioGroup,
    Radio,
    Modal, // Lägg till Modal här

} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/sv'; // Lägg till detta för att importera den svenska locale-filen
import './BookingForm.css';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FAQ from './FAQ'; // Importera FAQ-komponenten

import emailjs from 'emailjs-com';

import busImage from './pictures/bus.webp'; // Importera bussbilden
import planeImage from './pictures/plane.png';


// Importera bilder för headern
import headimage from './pictures/headimage.webp';
import smstrip from './pictures/smstrip.webp'; // Importera bilden

// Importera bilder för triptyper
import aventyrImage from './pictures/aventyr.webp';
import avkopplingImage from './pictures/avkoppling.webp';
import stadsresaImage from './pictures/stadsresa.webp';
import kulturresaImage from './pictures/kulturresa.webp';
import lyxresaImage from './pictures/lyxresa.webp';
import naturImage from './pictures/natur.webp';
import wildcardImage from './pictures/wildcard.webp';
import sportImage from './pictures/sport.webp';
import carImage from './pictures/bilSelect.png';
import carImageSelect from './pictures/bilActive.png';



// Bildslider-komponent
function ImageSlider() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [faqOpen, setFaqOpen] = useState(false); // För FAQ Modal
    const [headImageLoaded, setHeadImageLoaded] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleFAQ = () => {
        setFaqOpen(!faqOpen); // Direkt styrning av FAQ-modalen
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '430px',
                overflow: 'hidden',
                textShadow: '0px 3px 7px rgba(0, 0, 0, 0.8)',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '850px',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                    textShadow: '0px 3px 7px rgba(0, 0, 0, 0.8)',
                }}
            >
<Box
    sx={{
        width: '100%',
        maxWidth: '850px',
        height: '100%',
        display: headImageLoaded ? 'flex' : 'none',  // Visar hela innehållet när bilden är laddad
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',

        textShadow: '0px 3px 7px rgba(0, 0, 0, 0.8)',
    }}
/>

    <Box
        component="img"
        src={headimage}
        alt="Header Image"
        onLoad={() => setHeadImageLoaded(true)}
        sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            animation: 'zoomInOut 30s linear infinite',
        }}
    />


    <Typography
        variant="h3"
        sx={{
            position: 'absolute',
            top: '13px',
            left: '20px',
            color: 'white',
            fontWeight: '600',
            fontSize: '1.4rem',
            textShadow: '0px 0px 10px rgba(999, 999, 999, 0.8)',
            textShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
        }}
    >
HEMLIGRESA

    </Typography>

                {/* Lägg till menyknapp (hamburgare) längst upp till höger */}
                <Box
                    onClick={toggleMenu}
                    sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '20px',
                        color: 'white',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        textShadow: '0px 3px 7px rgba(0, 0, 0, 0.8)',
                    }}
                >
                    {menuOpen && (
<Box sx={{ display: 'flex', ml: 2, gap: 2 }}>
<Typography
    variant="h7"
    component="a"
    href="mailto:hej@hemligresa.se"
    sx={{
        cursor: 'pointer',
        color: 'white',
        textDecoration: 'none' // Tar bort understrykning
    }}
>
    KONTAKT
</Typography>


    {/* FAQ-knappen som inte är beroende av menyn */}
    <Typography
        variant="h7"
        sx={{
            cursor: 'pointer',
            color: 'white',
            marginRight: '20px',
        }}
        onClick={toggleFAQ} // Öppnar FAQ-modalen direkt
    >
        FAQ
    </Typography>

</Box>

                    )}

                    {menuOpen ? (
                        <CloseIcon sx={{ fontSize: '2rem' }} />
                    ) : (
                        <MenuIcon sx={{ fontSize: '2rem' }} />
                    )}
                </Box>



                {/* FAQ Modal */}
                <Modal
                    open={faqOpen}
                    onClose={toggleFAQ}
                    aria-labelledby="faq-title"
                    aria-describedby="faq-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '8px',
                            width: '80%',
                            maxWidth: '600px',
                            maxHeight: '80vh',
                            overflow: 'auto',
                        }}
                    >
                        <FAQ /> {/* Visa FAQ-komponenten */}
                    </Box>
                </Modal>

                {/* Huvudtext i mitten */}
                <Typography
                    variant="h1"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: 'white',
                        fontWeight: 'bold',
                        width: '90%',
                        textShadow: '0px 0px 7px rgba(0, 0, 0, 0.6), 0px 0px 10px rgba(999, 999, 999, 0.4)',
fontSize: {
    '@media (max-width: 430px)': {
        fontSize: '1.8rem', // Anpassning för skärmar upp till 400px
    },
    xs: '2.1rem',  // Extra små skärmar
    sm: '2.5rem',  // Små skärmar
    md: '3.5rem'   // Medelstora skärmar
},

                        textAlign: 'center',
                    }}
                >
                    DIN SPÄNNANDE ÖVERRASKNINGSRESA VÄNTAR!
                </Typography>
            </Box>
        </Box>
    );
}

function loadGA() {
    // Kolla om gtag.js redan har laddats
    if (!window.gtag) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-V0RKP9BKCZ';
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            window.gtag = gtag;
            gtag('js', new Date());
            gtag('config', 'G-V0RKP9BKCZ');
        };
    }
}















































function BookingForm() {

    const [selectedTrip, setSelectedTrip] = useState('');
    const [blinking, setBlinking] = useState(false);

    const [showInfoBox, setShowInfoBox] = useState(false);

const [showMissionBox, setShowMissionBox] = useState(false);
const [initialScrollTriggered, setInitialScrollTriggered] = useState(false);

const [formData, setFormData] = useState({
    departureDate: null,
    departureTime: '', // Ny state-variabel för tid
    departureCity: '',
    days: '',
    people: '',
    budgetPerPerson: '',
    loginiva: '',
    aktivitetsniva: '',
    reseniva: '',
    kostniva: '',
    carAvailable: '',
    hasLicense: '', // Ny state-variabel för körkortsinformation
    tripType: '',
    name: '',
    age: '', // Ny state-variabel för ålder
    preferences: '',
    phone: '',
    email: '',
    distance: '',
    avoid: '',
});

const [hasShownMissionBox, setHasShownMissionBox] = useState(false);
const [hasScrolledUp, setHasScrolledUp] = useState(false);

const [missionBoxClosed, setMissionBoxClosed] = useState(true);

const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
const reviews = [
    {
        text: "Jag fick en destination skickad till mig morgonen för avfärd och hamnade på ett litet hotell vid en sjö. Dagen fylldes med kajakpaddling och en fantastisk middag vid solnedgången. Det var exakt den avkoppling jag behövde, och jag hade aldrig själv valt just det här stället!",
        author: "Emma Larsson"
    },
    {
        text: "Det var en perfekt kombination av spänning och planering. Jag och min vän fick en GPS-koordinat och blev ledda till en vingård som vi aldrig hört talas om. Vi fick en guidad tur och det hela avslutades med en vinprovning under stjärnorna. Jag kommer definitivt boka igen!",
        author: "David Nilsson"
    },
    {
        text: "Jag blev överraskad med en resa till en bergsstuga, där jag fick prova på klättring för första gången. Det var lite skrämmande men så givande. Instruktörerna var otroliga och hela upplevelsen var något jag aldrig skulle ha bokat själv. Det var verkligen utanför min komfortzon!",
        author: "Sofia Persson"
    },
    {
        text: "Jag älskar att resa, men jag har aldrig upplevt något som detta! Att bli överraskad med en helt oväntad destination och en helg full av avkoppling vid en avlägsen strand var precis vad jag behövde. Att bara kunna koppla av utan att behöva planera något var fantastiskt!",
        author: "Clara Björk"
    },
    {
        text: "Jag hade alltid varit skeptisk till överraskningsresor, men detta ändrade allt. När jag fick veta att jag skulle åka till en plats utomlands, blev jag riktigt nervös, men det blev helt fantastiskt! Jag hamnade på en liten vingård i Toscana, där jag fick uppleva en vinprovning och lära mig om olivoljeproduktion. En upplevelse jag aldrig hade kunnat drömma om!",
        author: "Marcus Lindberg"
    }
];


const handleNextReview = () => {
    setCurrentReviewIndex((prevIndex) =>
        prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
};

const handlePrevReview = () => {
    setCurrentReviewIndex((prevIndex) =>
        prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
};


const closeMissionBox = () => {
    setMissionBoxClosed(true);
    setShowMissionBox(false);
    
};

useEffect(() => {
    // Ladda Facebook Pixel om det inte redan är inladdat
    if (!window.fbq) {
        !function(f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function() {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1044605103928569'); // Replace with your Facebook Pixel ID
    }

    fbq('track', 'PageView'); // Spåra sidvisning
}, []);


useEffect(() => {
    const handleScroll = () => {
        // Hantera visning av info-box vid 600px scroll
        if (window.scrollY > 600) {
            setShowInfoBox(true);
        } else {
            setShowInfoBox(false);
        }

        // Kontrollera om användaren har scrollat upp till toppen
        if (window.scrollY === 0) {
            setHasScrolledUp(true);
        }

        // Hantera visning av mission-box vid 2000px scroll
        // Endast om användaren har scrollat upp och sedan ned igen, och missionBoxClosed är sant
        if (window.scrollY > 2000 && missionBoxClosed && hasScrolledUp) {
            setMissionBoxClosed(false);
            setHasScrolledUp(false); // Nollställ så att den inte visas omedelbart igen
        }


    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
}, [missionBoxClosed, hasScrolledUp, initialScrollTriggered]);



    useEffect(() => {
        // Ladda Google Analytics när komponenten laddas
        loadGA();
    }, []);

        useEffect(() => {
        let blinkInterval;
        if (!selectedTrip) {
            blinkInterval = setInterval(() => {
                setBlinking(prev => !prev);
            }, 2000);
        } else {
            setBlinking(false);
        }

        return () => clearInterval(blinkInterval);
    }, [selectedTrip]);


    const [error, setError] = useState('');

    const handleChangeTripType = (tripType) => {
        setFormData((prev) => {
            const selectedTrips = prev.tripType
                ? prev.tripType.split(',')
                : [];
            if (selectedTrips.includes(tripType)) {
                // Ta bort resetypen om den redan är vald
                const updatedTrips = selectedTrips.filter(
                    (trip) => trip !== tripType
                );
                return { ...prev, tripType: updatedTrips.join(',') };
            } else if (selectedTrips.length < 3) {
                // Lägg till resetypen om färre än 3 är valda
                return {
                    ...prev,
                    tripType: [...selectedTrips, tripType].join(','),
                };
            } else {
                // Visa felmeddelande om maxgränsen nås
                setError('Du kan endast välja 3 resetyper.');
                setTimeout(() => setError(''), 3000); // Ta bort felmeddelandet efter 3 sekunder
                return prev;
            }
        });
    };

    



    const departureCities = [
    'Stockholm',
    'Göteborg',
    'Malmö',
    'Uppsala',
    'Västerås',
    'Örebro',
    'Linköping',
    'Helsingborg',
    'Jönköping',
    'Norrköping',
    'Växjö', // I mitten av Småland
    'Kalmar',
    'Lund',
    'Karlskrona',
    'Halmstad',
    'Karlstad',
    'Borås',
    'Trollhättan',
    'Kristianstad',
    'Falun',
    'Sundsvall',
    'Gävle',
    'Luleå', // Ett alternativ i norr
    'Umeå',
    'Visby', // För Gotland
    ''
];



const [carImageSrc, setCarImageSrc] = useState(carImage); // Bilen
const [busImageSrc, setBusImageSrc] = useState(busImage); // Bussen

const [progress, setProgress] = useState(0);

const [isMinimized, setIsMinimized] = useState(false);




useEffect(() => {
    const totalFields = 17; // Ändra detta till antalet obligatoriska fält i formuläret
    const filledFields = Object.values(formData).filter(value => value && value !== '').length;
    const calculatedProgress = Math.round((filledFields / totalFields) * 100);
    setProgress(calculatedProgress);
}, [formData]);


    const costLevels = ['Budget', 'Standard', 'Lyx'];






const [loading, setLoading] = useState(false);
const [submitted, setSubmitted] = useState(false); // Ny state för skickad förfrågan
    const [errors, setErrors] = useState({});

const handleChange = (e) => {
    const { name, value } = e.target;

    // Uppdatera formData med det nya värdet
    setFormData((prev) => ({
        ...prev,
        [name]: value, // Uppdaterar vilken som helst input baserat på dess namn
    }));

    // Hantera bilens bild om användaren uppdaterar carAvailable
    if (name === 'carAvailable') {
        setCarImageSrc(value === 'yes' ? carImageSelect : carImage);
    }

    // Om användaren uppdaterar hasLicense, hantera bilens logik och nollställ bilvalet
    if (name === 'hasLicense' && value === 'no') {
        setFormData((prev) => ({
            ...prev,
            carAvailable: '', // Nollställer bilens tillgänglighet om körkortet är 'no'
        }));
        setCarImageSrc(carImage); // Återställ bilden till standard om körkort är "no"
    }

    // Räkna antalet ifyllda fält
    const filledFieldsCount = Object.values(formData).filter(val => val !== '').length;

    // Skicka olika Meta Pixel-händelser baserat på antal ifyllda fält
    if (filledFieldsCount >= 1 && filledFieldsCount <= 5) {
        fbq('trackCustom', 'FormFieldFilled_1to5', {
            fieldName: name,               // Vilket fält användaren fyller i
            filledFieldsCount: filledFieldsCount, // Antal fält som är ifyllda
        });
    } else if (filledFieldsCount >= 6 && filledFieldsCount <= 10) {
        fbq('trackCustom', 'FormFieldFilled_6to10', {
            fieldName: name,
            filledFieldsCount: filledFieldsCount,
        });
    } else if (filledFieldsCount >= 11 && filledFieldsCount <= 17) {
        fbq('trackCustom', 'FormFieldFilled_11to17', {
            fieldName: name,
            filledFieldsCount: filledFieldsCount,
        });
    }
};



const handleDateChange = (date) => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    setFormData((prev) => ({ ...prev, departureDate: formattedDate }));
};


const handleSubmit = async (e) => {
    e.preventDefault();

    // Kolla om formuläret är giltigt
    if (!validateForm()) {
        console.log('Form validation failed', errors);
        return;
    }

    // Logga formData till konsolen
    console.log('Formulärdata som skickas:', JSON.stringify(formData, null, 2));

    setLoading(true);

    try {
        console.log('Försöker skicka formulär via EmailJS...');
        const result = await emailjs.send(
            'service_pop2ogi',    // Byt ut mot din EmailJS service ID
            'template_vc09yo3',   // Byt ut mot din EmailJS template ID
            formData,
            'QFd_ENYXMG_mZwSdB'   // Byt ut mot din EmailJS public key
        );
        
        console.log('EmailJS response:', result);  // Logga svaret från EmailJS
        setSubmitted(true);  // Sätt submitted till true när det lyckas

        // Skicka en Meta Pixel-händelse för att spåra formulärinskickning
        const filledFieldsCount = Object.values(formData).filter(val => val !== '').length;

        fbq('track', 'Lead', {
            filledFieldsCount: filledFieldsCount, // Antal fält som var ifyllda vid inskick
            formType: 'BookingForm'               // Ange vilken typ av formulär det är
        });

    } catch (error) {
        console.error('EmailJS error:', error);  // Logga felet från EmailJS
        alert('Det gick inte att skicka din förfrågan.');
    } finally {
        setLoading(false);
    }
};



const validateForm = () => {
    let tempErrors = {};
    if (!formData.departureDate) tempErrors.departureDate = 'Vänligen välj ett avresedatum.';
    if (!formData.departureCity) tempErrors.departureCity = 'Vänligen välj en avresestad.';
    if (!formData.days) tempErrors.days = 'Vänligen ange antal dagar.';
    if (!formData.people) tempErrors.people = 'Vänligen ange antal personer.';
    if (!formData.budgetPerPerson) tempErrors.budgetPerPerson = 'Vänligen ange en budget per person.';
    if (!formData.carAvailable) tempErrors.carAvailable = 'Vänligen svara på om du har bil tillgänglig.';
    if (!formData.tripType) tempErrors.tripType = 'Vänligen välj typ av resa.';  // Lägg till felmeddelande för triptypen
    if (!formData.name) tempErrors.name = 'Vänligen ange ditt namn.';
    if (!formData.age) tempErrors.age = 'Vänligen ange din ålder.';
    if (!formData.phone) tempErrors.phone = 'Vänligen ange ditt telefonnummer.';
    if (!formData.email) tempErrors.email = 'Vänligen ange din e-postadress.';
    
    setErrors(tempErrors);
    
    return Object.keys(tempErrors).length === 0; // Om det finns fel, returnera false
};






const formatTripTypes = (tripTypes) => {
    const typesArray = tripTypes.split(',').map(type => type.trim());

    const formatType = (type, isLast) => {
        if (type === 'Äventyr' && isLast) return 'Äventyrs';
        if (type === 'Avkoppling' && isLast) return 'Avkopplings';
        if (type === 'Stad' && isLast) return 'Stads';
        return type;  // Returnerar oförändrad om det inte är någon av de ovan eller om det inte är sista typen
    };

    if (typesArray.length > 1) {
        const lastType = formatType(typesArray.pop(), true);  // Format sista resetypen
        return `${typesArray.join(', ')} och ${lastType}resa`;  // Lägg till "resa" bara på den sista
    }

    // Om det bara finns en typ, format och lägg till "resa"
    return `${formatType(typesArray[0], true)}resa`;
};



// Lägg till useEffect för att logga formData när hasLicense ändras
useEffect(() => {
    console.log('Form data uppdaterad vid förändring av hasLicense:', formData);
}, [formData.hasLicense]);



return (
    <Container maxWidth="md" className="booking-form-container" disableGutters>
        <ImageSlider />

{/* Resten av formuläret */}
<Box sx={{ padding: '25px', position: 'relative',  color: '#ffffff'}}>




{showInfoBox && (
        <div
            id="info-box"
            style={{
                display: 'block',
                position: 'fixed',
                left: '0',
                bottom: '0',
                width: '100%',
                backgroundColor: '#ffffff',
                marginBottom: '-5px',
                paddingBottom: '40px',
                boxShadow: '0 -1px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 100,
                color: '#585858',
                transition: 'transform 0.3s ease-in-out, padding-block 0.3s ease-in-out',
                transform: isMinimized ? 'translateY(calc(100% - 45px))' : 'translateY(0)',
            }}
        >
            <div
                style={{
                    maxWidth: '850px',
                    margin: '0 auto', // Centrerar innehållet horisontellt
                    padding: '0 25px',
                }}
            >
                {/* Header med titel och knapp */}
                <Box
                    sx={{
                        display: 'flex',
                        height: '40px',
                        marginLeft: '-25px',

                        marginRight: '-25px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: 
                            formData.colorChoice === 'yellow' ? '#FFB65B' :
                            formData.colorChoice === 'red' ? '#b55462' :
                            formData.colorChoice === 'blue' ? '#5599bb' :
                            formData.colorChoice === 'green' ? '#398378' :
                            '#c7c7c7', // Standardfärg om ingen färg är vald
                        padding: '0px 15px',
                        color: 'white',
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: '900' }}>
                        DIN HEMLIGA RESA
                    </Typography>
                    <Button
                        onClick={() => setIsMinimized(prev => !prev)}
                        sx={{
                            color: 'white',
                            fontSize: '29px',
                            marginRight: '-21px',

                            transform: isMinimized ? 'rotate(270deg)' : 'rotate(90deg)',
                            transition: 'transform 0.3s ease',
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: 
                                    formData.colorChoice === 'yellow' ? '#e6a34d' :
                                    formData.colorChoice === 'red' ? '#a24956' :
                                    formData.colorChoice === 'blue' ? '#4d88a3' :
                                    formData.colorChoice === 'green' ? '#357469' :
                                    'rgba(0, 0, 0, 0.1)',
                            },
                        }}
                    >
                        {isMinimized ? '›' : '›'}
                    </Button>
                </Box>

                {/* Önskemål-rubrik */}
                <div
                    style={{
                        fontSize: '15px',
                        fontWeight: '900',
                        marginInline: '-25px',
                        backgroundColor: '#f6f6f6',
                        padding: '5px 25px',
                        textAlign: 'left',
                    }}
                >
                    Önskemål
                </div>

                {/* Visa resetyper om valda */}
                {formData.tripType && (
                    <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '15px',
                        marginBottom: '10px',
                    }}
                    >
                    <Typography
                        variant="body1"
                        sx={{
                            fontSize: '20px',
                            fontWeight: '1000',
                            textAlign: 'center',
                            height: '100%',
                            paddingInline: '15px',
                            paddingBlock: '4px',
                            borderBottom: '2px solid #f6f6f6', // Justerad border-bottom

                        }}
                    >
                        <strong></strong> {formatTripTypes(formData.tripType)}
                    </Typography>
                    </Box>
                )}

<div>
{/* Datum, tid och dagar */}
<Box
    sx={{
        display: 'flex',
        width: '100%'
    }}
>
    {/* Visa datum och tid om båda är tillgängliga */}
    {formData.departureDate && (
        <Typography variant="body1" sx={{  }}>
            <strong>Datum:</strong> {formData.departureDate} {formData.departureTime && `| ${formData.departureTime}`}
        </Typography>
    )}

    {formData.days && (
        <Typography variant="body1" sx={{  textAlign: 'right', marginLeft: 'auto',  }}>
            <strong>Dagar:</strong> {formData.days}

        </Typography>
        
    )}
</Box>


{Object.values(formData).some(value => value) && (
    <Box
        sx={{
            backgroundColor: 'lightgray',
            borderRadius: '10px',
            overflow: 'hidden',
            bottom: '10px',
            width: '100%',
            height: '2px', // Ökar höjden för att göra den mer synlig
            marginTop: '10px',
        }}
    >
        <Box
            sx={{
                width: `${progress}%`,
                height: '100%',
                zIndex: 200,
                backgroundColor: 'black',
                transition: 'width 0.3s ease-in-out',
            }}
        />
    </Box>
)}


                    </div>
                    {formData.people && (
                        <Typography variant="body1" sx={{ marginTop: '10px' }}>
                            <strong>Antal personer:</strong> {formData.people}
                        </Typography>
                    )}

{/* Avresestad, distans och bil */}
<Box
    sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
    }}
>
    {formData.departureCity && (
        <Typography variant="body1">
            <strong>Avresestad:</strong> {formData.departureCity}
        </Typography>
    )}

{formData.hasLicense && (
    <Typography variant="body1" sx={{  }}>
        <strong>Körkort:</strong> {formData.hasLicense === 'yes' ? 'Ja' : 'Nej'}
        {formData.hasLicense === 'yes' && (
            <span style={{ marginLeft: '15px' }}>
                <strong>Bil:</strong> {formData.carAvailable === 'yes' ? 'Ja' : 'Nej'}
            </span>
        )}
    </Typography>
)}


</Box>



                                            {formData.distance && (
                            <Typography variant="body1" sx={{  marginRight: '50px' }}>
                                <strong>Distans:</strong> {formData.distance}
                            </Typography>
                        )}



{/* Kostnadsnivåer */}
{(formData.aktivitetsniva || formData.loginiva || formData.kostniva || formData.reseniva) && (
    <>

        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)', // Fyra kolumner
                gap: '10vw', // Mellanrum mellan kolumner
                marginTop: '30px',
                marginBottom: '10px',
            }}
        >
            {formData.aktivitetsniva && (
                <Box>
                    <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                        Aktiviteter
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: 'left' }}>
                        {formData.aktivitetsniva}
                    </Typography>
                </Box>
            )}
            {formData.loginiva && (
                <Box>
                    <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                        Boende
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: 'left' }}>
                        {formData.loginiva}
                    </Typography>
                </Box>
            )}
            {formData.kostniva && (
                <Box>
                    <Typography variant="body2" sx={{ textAlign: 'left' , fontWeight: 'bold'}}>
                        Mat
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: 'left' }}>
                        {formData.kostniva}
                    </Typography>
                </Box>
            )}
            {formData.reseniva && (
                <Box>
                    <Typography variant="body2" sx={{ textAlign: 'left', fontWeight: 'bold'}}>
                        Resor
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: 'left' }}>
                        {formData.reseniva}
                    </Typography>
                </Box>
            )}
        </Box>
    </>
)}



{/* Budget */}
{formData.budgetPerPerson && (
    <Box
        sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '10px',
            borderTop: '1px solid #ddd',
            paddingTop: '10px',

        }}
    >
        <Typography variant="body1"
        sx={{
                display: 'flex',
                fontWeight: 'bold', // Gör summan fetstil

            }}>
            Budget per person:
        </Typography>
        <Typography 
        
            variant="body1"
            sx={{
                display: 'flex',
            }}
        >
            {formData.budgetPerPerson} SEK
        </Typography>
    </Box>
)}

</div>
</div>
)}


















<Box
sx={{
marginBottom: -2,
maxWidth: { xs: '100%', md: '800px' },
mx: 'auto',
color: '#333',  // Mörkare färg för texten
}}
>
<Box
sx={{
    display: 'grid',
    gap: 2,
}}
>
<Typography
    align="left"
    className="form-description1"
    sx={{
    lineHeight: 1.8,
    marginBottom: 2,
    marginTop: 2,
    fontSize: 19.5,
    color: '#333',
    }}
>
    <strong>Drömmer du om att kasta dig ut i ett äventyr där varje steg är oförutsägbart?</strong> Välkommen till en resa som tar dig bortom gränserna för det vanliga. Föreställ dig att du står på startlinjen för ett unikt äventyr – där varje destination hålls hemlig tills du får din nästa ledtråd via <strong>SMS</strong>.
    <br />
    <br /> Genom att fylla i dina önskemål och budget nedan har du chansen att bli utvald för en exklusiv och skräddarsydd upplevelse som tar dig till platser du aldrig hade föreställt dig.
    <br />
</Typography>

<Box
    sx={{
    marginTop: '10px',
    marginBottom: '40px',
    }}
>
    <img
    src={smstrip}
    alt="Adventure journey"
    style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        marginBottom: '-50%',
    }}
    />
</Box>

<Typography
    align="left"
    className="form-description1"
    sx={{
    lineHeight: 1.8,
    marginBottom: 2,
    fontSize: 19.5,
    color: '#333',
    }}
>
    <strong>Vi tar hand om allt</strong> – från transport till boende och upplevelser som du aldrig kommer att glömma. Din enda uppgift är att luta dig tillbaka och låta oss skapa ett skräddarsytt äventyr åt dig.
    <br />
    <br />
    <strong>Varje resa är noggrant utvald och helt unik</strong>. Med ett begränsat antal resor varje månad garanterar vi en exklusiv upplevelse för de som blir utvalda.  
    <br />
    <br />
    <strong>Redo för ditt livs äventyr?</strong> Fyll i dina detaljer nedan och ta steget mot en resa full av överraskningar.
</Typography>
</Box>
</Box>

{!submitted ? (
    <form onSubmit={handleSubmit}>
        {/* Formuläret */}



<div
    id="mission-box"
    style={{
        display: missionBoxClosed ? 'none' : 'block',
        position: 'fixed',
        top: showMissionBox ? '0px' : '5px',
        right: showMissionBox ? '0px' : '5px',
        backgroundColor: '#ffffff',
        padding: '15px',
        boxShadow: '0 1px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        zIndex: 150,
        color: '#585858',
    }}
>
    <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
            variant="h8"
            sx={{ fontWeight: 'bold', cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => setShowMissionBox(!showMissionBox)}
        >
            Vill du vara med och skapa andras resor?
        </Typography>
        <Button
            onClick={closeMissionBox}
            sx={{
                minWidth: 'unset',
                padding: '2px 8px',
                color: '#555',
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
            }}
        >
            ✖
        </Button>
    </Box>


{showMissionBox && (
    <>
        {/* Lägg till style-block för keyframes direkt i komponenten */}
        <style>
            {`
                @keyframes colorChange {
                    from {
                        color: #d7d7d7;
                    }
                    to {
                        color: #9D9D9D;
                    }
                }
            `}
        </style>

        <Typography variant="body2" sx={{ marginTop: '10px', marginLeft: '10px' }}>
            Har du koll på landets dolda pärlor? Hjälp oss att upptäcka dem och tjäna samtidigt en slant! Följ dessa enkla steg för att delta:
            <ol style={{ marginTop: '10px', marginLeft: '-25px' }}>
                <li>
                    Skapa ett konto på{' '}
                    <a 
                        href="https://makeamission.com" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ 
                            color: '#E1E1E1', 
                            textDecoration: 'none', 
                            animation: 'colorChange 2s infinite alternate'
                        }}
                    >
                        Make A Mission
                    </a>, som är en marknad för mikrotjänster.
                </li>
                <li>
                    Skicka in en förfrågan med ett erbjudande på{' '}
                    <a 
                        href="https://makeamission.com/sv/play/dWPmyDbCsS9Sr8AhWCJP" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ 
                            color: '#E1E1E1', 
                            textDecoration: 'none', 
                            animation: 'colorChange 2s infinite alternate'
                        }}
                    >
                        denna uppdragssida
                    </a>.
                </li>
                <li>
                    Vi skickar dig en kunds anonyma reseuppdrag som du får möjlighet att planera åt oss.
                </li>
                <li>
                    Få betalt för ditt bidrag och njut av dina intjänade pengar!
                </li>
            </ol>
        </Typography>
    </>
)}



</div>



    {errors.tripType && (
    <Typography variant="h5" color="error" align="center" marginTop="40px" marginBottom="-20px">
        {errors.tripType}
    </Typography>
)}

                {/* Resten av formuläret */}
                <Grid container spacing={3} sx={{ marginTop: '30px' }}>
                    {/* Triptyper */}
<Grid item xs={12}>

<Box sx={{ position: 'relative', textAlign: 'center', display: 'inline-block', width: '100%' }}>
    
    <Typography
        component="h2"
        variant="h5"
        gutterBottom
        sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginBottom: '30px',
            fontSize: '1.6rem',
            color: '#555',
            position: 'relative',
            '&::after': {
                content: '""',
                display: 'block',
                width: '100%',
                height: '1.5px',
                backgroundColor: '#333',
                opacity: '0.7',
                margin: '8px auto 0',
            },
        }}
    >
        Välj Typ av Resa
    </Typography>
    
    <Typography
        variant="body2"
        sx={{
            position: 'absolute',
            top: '-20px', // Placerar texten ovanför rubriken
            right: '10%', // Justera för att placera texten snett till höger
            color: '#777',
            fontSize: '0.9rem',
            fontStyle: 'italic',
        }}
    >
        Max 3 val
    </Typography>
    
</Box>


        {/* Lägg till felmeddelande */}
<Modal
    open={!!error}
    onClose={() => setError('')}
    aria-labelledby="error-message"
    aria-describedby="error-description"
>
    <Box
        onClick={() => setError('')}
        sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: 4,
            boxShadow: 24,
            borderRadius: 2,
            textAlign: 'center',
            maxWidth: '400px',
            width: '90%',
        }}
    >
        <Typography 
            id="error-message" 
            variant="h6" 
            sx={{ color: '#888888' }} // Ändrar färgen till grå
        >
            {error}
        </Typography>
    </Box>
</Modal>




<Grid container spacing={4}>
    {[

        {
            value: 'Äventyr',
            label: 'Äventyr - Extrema sporter och adrenalinfyllda aktiviteter',
            image: aventyrImage,
        },
        {
            value: 'Avkoppling',
            label: 'Avkoppling - Lugna miljöer och spa',
            image: avkopplingImage,
        },
        {
            value: 'Kultur',
            label: 'Kultur - Museer och historiska platser',
            image: kulturresaImage,
        },
        {
            value: 'Stad',
            label: 'Stadsliv - Utforska en ny stad',
            image: stadsresaImage,
        },
        {
            value: 'Natur',
            label: 'Natur - Vandring och aktiviteter',
            image: naturImage,
        },
        {
            value: 'Sport',
            label: 'Sport - Sportevenemang och aktiviteter',
            image: sportImage,
        },
        {
            value: 'Lyx',
            label: 'Lyxsemester - Femstjärniga hotell och exklusiva aktiviteter',
            image: lyxresaImage,
        },
                {
            value: 'Wildcard',
            label: 'Wildcard - En oväntad och överraskande resa',
            image: wildcardImage,
        },
    ].map((type) => (
        <Grid item xs={12} sm={6} key={type.value}>
            <Card
                className={`trip-type-card ${
                    formData.tripType?.split(',').includes(type.value) ? 'selected' : ''
                }`}
                onClick={() => handleChangeTripType(type.value)}
                sx={{
                    cursor: 'pointer',
                    height: '230px',
                    width: '100%',
                }}
            >
                <CardActionArea sx={{ height: '100%', position: 'relative' }}>
<CardMedia
    component="img"
    className="card-media"
    sx={{
        width: '100%',
        height: '100%',
        filter: formData.tripType?.split(',').includes(type.value)
            ? 'none'
            : 'grayscale(100%)',
        animation: !formData.tripType?.split(',').includes(type.value)
            ? `pulseColor 5s infinite ${Math.random() * 2}s`  // Lägger till en slumpmässig fördröjning
            : 'none',
        '@keyframes pulseColor': {
            '0%': { filter: 'grayscale(100%)' },
            '50%': { filter: 'grayscale(60%)' },
            '100%': { filter: 'grayscale(100%)' },
        },
    }}
    image={type.image}
    alt={type.label}
/>

                    {formData.tripType?.split(',').includes(type.value) ? (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '-3px',
                                left: '-2px',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                fontSize: '2.5rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textShadow: '0px 2px 7px rgba(0, 0, 0, 0.7)',
                            }}
                        >
                            {type.label.split(' - ')[0].toUpperCase()}
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                transition: 'background-color 0.3s ease',
                            }}
                        >
                            <Box
                                className="card-text"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    align="center"
                                    sx={{
                                        color: 'white',
                                        wordWrap: 'break-word',
                                        whiteSpace: 'normal',
                                        textShadow: '0px 2px 7px rgba(0, 0, 0, 0.7)',
                                        fontSize: '1.2rem',
                                        marginTop: '-25px',
                                        width: '80%',
                                    }}
                                >
                                    <strong>{type.label.split(' - ')[0]}</strong> - {type.label.split(' - ')[1]}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </CardActionArea>
            </Card>
        </Grid>
    ))}
</Grid>



<Grid container spacing={3}
sx={{
    marginTop: '20px',
}}
>
    




<Grid item xs={6} md={6}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sv">
    <DatePicker
        label="Avresedatum"
        value={formData.departureDate}
        onChange={handleDateChange}
        minDate={new Date()} 
        renderInput={(params) => (
        <TextField
            fullWidth
            required
            {...params}
            name="departureDate"
            variant="outlined"
        />
        )}
    />
    </LocalizationProvider>
</Grid>

{/* Tredje raden */}
<Grid item xs={6} md={6}>
    <FormControl fullWidth required variant="outlined">
    <InputLabel>Avresestad</InputLabel>
    <Select
        label="Utgångspunkt"
        name="departureCity"
        value={formData.departureCity || ''}
        onChange={handleChange}
    >
        {departureCities.map((city) => (
        <MenuItem key={city} value={city}>
            {city}
        </MenuItem>
        ))}
    </Select>
    </FormControl>
</Grid>


<Grid item xs={6} md={6}>
    <TextField
    fullWidth
    required
    label="Antal personer"
    type="number"
    name="people"
    value={formData.people}
    onChange={handleChange}
    inputProps={{ min: 1 }}
    />
</Grid>

<Grid item xs={6} md={6}>
    <TextField
    fullWidth
    required
    label="Antal dagar"
    type="number"
    name="days"
    value={formData.days}
    onChange={handleChange}
    inputProps={{ min: 1 }}
    />
</Grid>
</Grid>



                        <Grid item xs={12}>
                            <Typography
                                component="h2"
                                variant="h5"
                                gutterBottom
                                sx={{
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    marginTop: '60px',
                                    fontSize: '1.6rem',
                                    color: '#555',
                                    position: 'relative',
                                    '&::after': {
                                        content: '""',
                                        display: 'block',
                                    width: '100%',
                                        height: '1.5px',
                                        backgroundColor: '#333',
                                        opacity: '0.7',
                                        margin: '8px auto 0',
                                    },
                                }}
                            >
                                Välj Kostnadsnivå
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* Kostnadsnivå */}
                    {[
                        { name: 'aktivitetsniva', label: 'Aktivitetsnivå' },
                        { name: 'loginiva', label: 'Boendenivå' },
                        { name: 'kostniva', label: 'Matnivå' },
                        { name: 'reseniva', label: 'Resenivå' },
                    ].map(({ name, label }, index) => (
                        <Grid item xs={6} sm={6} key={index}>
                            <FormControl fullWidth required variant="outlined">
                                <InputLabel>{label}</InputLabel>
                                <Select
                                    label={label}
                                    name={name}
                                    value={formData[name]}
                                    onChange={handleChange}
                                >
                                    {costLevels.map((level) => (
                                        <MenuItem key={level} value={level}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    ))}

                    {/* Extra information om restauranger */}
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ marginTop: '-15px', marginBottom: '0px', marginLeft:'4px', fontSize: '13px',color: 'grey' }}>
                            Vi bokar restauranger - du betalar maten själv.
                        </Typography>
                    </Grid>


                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            required
                            label="Budget per person (SEK)"
                            type="number"
                            name="budgetPerPerson"
                            value={formData.budgetPerPerson}
                            onChange={handleChange}
                            inputProps={{ min: 0 }}
                        />
                    </Grid>



<Grid container spacing={3}>
    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '60px', marginBottom: '30px', marginLeft: '25px' }}>
        {/* Bilbild med animation */}
<Grid item xs={12} display="flex" justifyContent="center">
    {/* Bilbild med animation */}
<Box
    component="img"
    src={carImageSrc}  // Bilanimation
    alt="Bil"
    sx={{
        position: 'absolute',
        zIndex: '2',
        width: '50px',
        marginTop: '65px',
        animation: 'moveCar 7s linear infinite',
        animationPlayState: formData.hasLicense === 'no' ? 'paused' : 'running',  // Bilen stannar bara om användaren valt "no"
        transform: formData.hasLicense === 'yes' && formData.carAvailable === 'yes'
            ? 'rotate(-5deg) translateY(-1px) scale(1.05)'  // Bilen lutar och flyttas upp om användaren har körkort och tillgång till bil
            : 'none',  // Ingen lutning annars
        '@keyframes moveCar': {
            '0%': { marginLeft: '-100vw' },
            '50%': { marginLeft: '0' },
            '100%': { marginLeft: '100vw' },
        },
    }}
/>






    {/* Bussbild med animation (spegelvänd) */}
    <Box
        component="img"
        src={busImageSrc}  // Bussanimation
        alt="Buss"
        sx={{
            position: 'absolute',
            width: '100px',
            marginTop: '40px',
            transform: 'scaleX(-1)',  // Spegelvänd bussbild
            animation: 'moveBus 10s linear infinite',
            animationPlayState: 
                formData.hasLicense === '' ||  // Om inget val har gjorts
                formData.hasLicense === 'no' || 
                (formData.hasLicense === 'yes' && formData.carAvailable === 'no') 
                ? 'running' 
                : 'paused',  // Bussen kör om inget val har gjorts eller om användaren inte har bil eller körkort
            '@keyframes moveBus': {
                '0%': { marginLeft: '-100vw' },
                '50%': { marginLeft: '0' },
                '100%': { marginLeft: '100vw' },
            },
        }}
    />
{formData.distance === 'Nationellt' || formData.distance === 'Internationellt' ? (
    <Box
        component="img"
        src={planeImage}
        alt="Plane"
        sx={{
            position: 'absolute',
            width: '60px',
            marginTop: '-30px',
            animation: 'movePlane 5s linear infinite',
            '@keyframes movePlane': {
                '0%': { marginLeft: '-100vw' },
                '50%': { marginLeft: '0' },
                '100%': { marginLeft: '100vw' },
            },
        }}
    />
) : null}

</Grid>





        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ marginTop: '20px', marginLeft: '20px' }}>
            {/* Fråga 1: Har du körkort? */}
            <Grid item xs={12} display="flex" justifyContent="center">
                <Typography
                    variant="h6"
                    align="center"
                    sx={{
                        fontWeight: 'bold',
                        marginBottom: '10px',
                        marginTop: '100px',
                        color: '#555',
                    }}
                >
                    Har du körkort?
                </Typography>
            </Grid>

<Grid item xs={12} display="flex" justifyContent="center">
    <RadioGroup
        aria-label="hasLicense"
        name="hasLicense"
        value={formData.hasLicense}  // Hämta direkt från formData
        onChange={(e) => {
            const newValue = e.target.value;

            // Uppdatera både hasLicense och carAvailable om användaren väljer "no"
            setFormData((prev) => ({
                ...prev,
                hasLicense: newValue,
                carAvailable: newValue === 'no' ? '' : prev.carAvailable, // Återställ carAvailable om körkort är "no"
            }));

            console.log('hasLicense ändrades till:', newValue);
            console.log('Uppdaterat formData:', formData);
        }}
        row
        sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
            fontWeight: 'bold',
            color: '#555',
        }}
    >
        <FormControlLabel value="yes" control={<Radio />} label="Ja" />
        <FormControlLabel value="no" control={<Radio />} label="Nej" />
    </RadioGroup>
</Grid>


            {/* Visa den andra frågan om användaren har svarat "Ja" på körkort */}
            {formData.hasLicense === 'yes' && (
                <>
                    {/* Fråga 2: Har du tillgång till egen bil? */}
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                fontWeight: 'bold',
                                marginBottom: '10px',
                                color: '#555',
                            }}
                        >
                            Har du tillgång till egen bil?
                        </Typography>
                    </Grid>

                    {/* Radio buttons för egen bil */}
                    <Grid item xs={12} display="flex" justifyContent="center">
                        <RadioGroup
                            aria-label="carAvailable"
                            name="carAvailable"
                            value={formData.carAvailable}
                            onChange={handleChange}
                            row
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: '20px',
                                fontWeight: 'bold',
                                color: '#555',
                            }}
                        >
                            <FormControlLabel value="yes" control={<Radio />} label="Ja" />
                            <FormControlLabel value="no" control={<Radio />} label="Nej" />
                        </RadioGroup>
                    </Grid>
                </>
            )}

            {errors.carAvailable && (
                <Typography variant="h6" color="error">
                    {errors.carAvailable}
                </Typography>
            )}
        </Grid>
    </Grid>
</Grid>











                <Grid item xs={6} md={6}>
            <TextField
                fullWidth
                required
                label="Önskad avresetid"
                type="time"
                name="departureTime"
                value={formData.departureTime}
                onChange={handleChange}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5-minuters steg
                }}
            />
        </Grid>

                <Grid item xs={6} md={6}>
            <FormControl fullWidth required variant="outlined">
                <InputLabel>Distans</InputLabel>
                <Select
                    label="Distans"
                    name="distance"
                    value={formData.distance || ''}
                    onChange={handleChange}
                >
                    <MenuItem value="Lokalt">Lokalt</MenuItem>
                    <MenuItem value="Regionalt">Regionalt</MenuItem>
                    <MenuItem value="Nationellt">Nationellt</MenuItem>
                    <MenuItem value="Internationellt">Internationellt</MenuItem>
                </Select>
            </FormControl>
        </Grid>




<Grid item xs={12}>
    <TextField
        fullWidth
        label="Övriga Önskemål..."
        type="text"
        name="preferences"
        value={formData.preferences}
        onChange={handleChange}
        multiline
        minRows={4}
        maxRows={10}
        sx={{
            marginTop: '0px',
        }}
    />
</Grid>












                    {/* Kontaktuppgifter */}
                    <Grid item xs={12}>
                        <Typography
                            component="h2"
                            variant="h5"
                            gutterBottom
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                fontWeight: 'bold',
                                marginTop: '40px',
                                fontSize: '1.6rem',
                                color: '#555',
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    display: 'block',
                                    width: '100%',
                                    height: '1.5px',
                                    backgroundColor: '#333',
                                    opacity: '0.7',
                                    margin: '8px auto 0',
                                },
                            }}
                        >
                            Kontaktuppgifter
                        </Typography>
                    </Grid>

<Grid item xs={12}>
    <TextField
        fullWidth
        required
        label="Namn"
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
    />
</Grid>

<Grid item xs={6} sm={6}>
    <TextField
        fullWidth
        required
        label="Ålder"
        type="number"
        name="age"
        value={formData.age}
        onChange={handleChange}
        inputProps={{ min: 1 }}
    />
</Grid>


                    <Grid item xs={6} sm={6}>
                        <TextField
                            fullWidth
                            required
                            label="Telefonnummer"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            placeholder="Ex: 0701234567"
                            error={!!errors.phone}
                            helperText={errors.phone}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            required
                            label="E-postadress"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                    </Grid>

<Grid item xs={12}>
    <Button
        variant="contained"
        type="submit"
        fullWidth
        endIcon={!loading && <SendIcon />}
        disabled={loading}
        sx={{
            textAlign: 'center',
            textTransform: 'uppercase',
            height: '65px',
            marginTop: '20px',
            marginBottom: '380px',
            fontSize: '23px',
            fontWeight: 'bold',

            
        }}
    >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Skicka förfrågan'}
    </Button>
</Grid>



                </Grid>
    </form>
) : (
<Box
    sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh', // Full höjd för att centrera vertikalt
        textAlign: 'center',
        backgroundColor: '#f5f5f5', // Lägg till bakgrundsfärg för tydlighet
    }}
>
    <Typography variant="h3" sx={{ fontWeight: 'bold', color: '#333' }}>
        Din förfrågan har skickats!
    </Typography>

        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333', marginTop: '20px'}}>
        Vi återkommer snart med mer information om din hemliga resa.
    </Typography>
</Box>

)}

        </Box>
    </Container>
);

                        }

export default BookingForm;
